var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_ap_payment") } },
        [
          _c(
            "a-form",
            _vm._b(
              { attrs: { layout: "vertical", form: _vm.form } },
              "a-form",
              _vm.formItemLayout,
              false
            ),
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.invoiceType.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.invoiceType.decorator,
                                  expression: "formRules.invoiceType.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.invoiceType.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.invoiceType.placeholder
                                ),
                                showSearch: true,
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterOption,
                                loading: _vm.loading.loadingInvoiceType,
                                allowClear: true,
                                disabled: _vm.disabledForm
                              },
                              on: { change: _vm.onChangeInvoiceType }
                            },
                            _vm._l(_vm.dataInvoiceType, function(data, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(data.value) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(data.value) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.apPaymentType.label)
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.detail.paymentType) + " ")]
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.branch.label) }
                        },
                        [
                          _c("branch-select", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.branch.decorator,
                                expression: "formRules.branch.decorator"
                              }
                            ],
                            attrs: { disabled: _vm.disabledForm },
                            on: { change: _vm.handleChangeBranch }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.currency.label) }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.currency.decorator,
                                  expression: "formRules.currency.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.currency.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.currency.placeholder
                                ),
                                showSearch: true,
                                "filter-option": false,
                                loading: _vm.loading.loadingCurrency,
                                allowClear: true,
                                disabled: _vm.disabledForm
                              },
                              on: {
                                search: function(val) {
                                  return _vm.debounce(function() {
                                    return _vm.getCurrency(val)
                                  }, 400)
                                },
                                change: _vm.handleChangeCurrency
                              }
                            },
                            _vm._l(_vm.dataCurrency, function(data, index) {
                              return _c(
                                "a-select-option",
                                {
                                  key: index,
                                  attrs: { value: data.currencyCode }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s(data.currencyCode) + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s(data.currencyCode) + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.currencyFrom !== "IDR",
                              expression: "currencyFrom !== 'IDR'"
                            }
                          ],
                          attrs: { label: _vm.$t(_vm.formRules.rate.label) }
                        },
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.rate.decorator,
                                expression: "formRules.rate.decorator"
                              }
                            ],
                            staticClass: "w-100",
                            attrs: {
                              name: _vm.formRules.rate.name,
                              placeholder: _vm.$t(
                                _vm.formRules.rate.placeholder
                              ),
                              disabled: _vm.disabledForm,
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              precision: _vm.storeBaseDecimalPlace
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.paymentAmount.label)
                          }
                        },
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.paymentAmount.decorator,
                                expression: "formRules.paymentAmount.decorator"
                              }
                            ],
                            staticClass: "w-100",
                            attrs: {
                              formatter: _vm.formatterNumber,
                              parser: _vm.reverseFormatNumber,
                              precision: _vm.storeBaseDecimalPlace,
                              disabled: _vm.disabledForm,
                              name: _vm.formRules.paymentAmount.name,
                              placeholder: _vm.$t(
                                _vm.formRules.paymentAmount.placeholder
                              )
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.supplierName.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.supplierName.decorator,
                                  expression: "formRules.supplierName.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.supplierName.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.supplierName.placeholder
                                ),
                                "filter-option": false,
                                loading: _vm.loading.loadingSupplierName,
                                disabled: !_vm.isActualNew,
                                "show-search": "",
                                "allow-clear": ""
                              },
                              on: {
                                search: function(val) {
                                  return _vm.debounce(function() {
                                    return _vm.getSupplierName(val, "search")
                                  }, 400)
                                },
                                change: _vm.handleChangeSupplier
                              }
                            },
                            _vm._l(_vm.dataSupplierName, function(data) {
                              return _c(
                                "a-select-option",
                                { key: data.id, attrs: { value: data.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s(data.fullName) + " "
                                        )
                                      ]),
                                      _vm._v(" " + _vm._s(data.fullName) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm.detail.paymentType === "Applied"
                        ? _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.supplierBillingTo.label
                                )
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.supplierBillingTo.decorator,
                                    expression:
                                      "formRules.supplierBillingTo.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.supplierBillingTo.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.supplierBillingTo.placeholder
                                  ),
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.detail.paymentType === "Applied"
                        ? _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.bankSupplierNumber.label
                                )
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value:
                                        _vm.formRules.bankSupplierNumber
                                          .decorator,
                                      expression:
                                        "formRules.bankSupplierNumber.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name: _vm.formRules.bankSupplierNumber.name,
                                    placeholder: _vm.$t(
                                      _vm.formRules.bankSupplierNumber
                                        .placeholder
                                    ),
                                    showSearch: true,
                                    "option-filter-prop": "children",
                                    "filter-option": _vm.filterOption,
                                    loading:
                                      _vm.loading.loadingBankSupplierNumber,
                                    allowClear: true,
                                    disabled: !_vm.isActualNew
                                  },
                                  on: { change: _vm.handleChangeSupplierBank }
                                },
                                _vm._l(_vm.dataBankSupplierNumber, function(
                                  data,
                                  index
                                ) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: index,
                                      attrs: { value: data.bankAccNumber }
                                    },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data.bankAccNumber +
                                                    " " +
                                                    (data.bankName === null
                                                      ? ""
                                                      : "(" +
                                                        data.bankName +
                                                        ")")
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                data.bankAccNumber +
                                                  " " +
                                                  (data.bankName === null
                                                    ? ""
                                                    : "(" + data.bankName + ")")
                                              ) +
                                              " "
                                          )
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.detail.paymentType === "Applied"
                        ? _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.accountBankSupplierName.label
                                )
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.accountBankSupplierName
                                        .decorator,
                                    expression:
                                      "formRules.accountBankSupplierName.decorator"
                                  }
                                ],
                                attrs: {
                                  name:
                                    _vm.formRules.accountBankSupplierName.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.accountBankSupplierName
                                      .placeholder
                                  ),
                                  disabled: !_vm.isActualNew
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.apPaymentDate.label)
                          }
                        },
                        [
                          _c("a-date-picker", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.apPaymentDate.decorator,
                                expression: "formRules.apPaymentDate.decorator"
                              }
                            ],
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              placeholder: _vm.$t(
                                _vm.formRules.apPaymentDate.placeholder
                              ),
                              disabledDate: _vm.useDisabledFromTomorrow,
                              disabled: _vm.disabledForm
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.paymentMethod.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.paymentMethod.decorator,
                                  expression:
                                    "formRules.paymentMethod.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.paymentMethod.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.paymentMethod.placeholder
                                ),
                                "show-search": "",
                                "filter-option": false,
                                loading: _vm.loading.loadingPaymentMethod,
                                "allow-clear": "",
                                disabled: _vm.disabledForm
                              },
                              on: {
                                search: function(val) {
                                  return _vm.debounce(function() {
                                    return _vm.getPaymentMethod(val)
                                  }, 400)
                                },
                                change: _vm.handleChangePaymentMethod
                              }
                            },
                            _vm._l(_vm.dataPaymentMethod, function(
                              data,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(data.value) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(data.value) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.bankNumber.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.bankNumber.decorator,
                                  expression: "formRules.bankNumber.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.bankNumber.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.bankNumber.placeholder
                                ),
                                showSearch: true,
                                "filter-option": false,
                                loading: _vm.loading.loadingBankNumber,
                                allowClear: true,
                                disabled: _vm.disabledForm
                              },
                              on: {
                                search: function(val) {
                                  return _vm.debounce(function() {
                                    return _vm.getBankNumber(val)
                                  }, 400)
                                }
                              }
                            },
                            _vm._l(_vm.dataBankNumber, function(data, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              data.bankAccNumber +
                                                " (" +
                                                data.bankName +
                                                ")"
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            data.bankAccNumber +
                                              " (" +
                                              data.bankName +
                                              ")"
                                          ) +
                                          " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm.show.chequeNumber
                        ? _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.chequeNumber.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.chequeNumber.decorator,
                                    expression:
                                      "formRules.chequeNumber.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRules.chequeNumber.name,
                                  placeholder: _vm.$t(
                                    _vm.formRules.chequeNumber.placeholder
                                  ),
                                  disabled: _vm.disabledForm
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.show.chequeNumber
                        ? _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.chequeDate.label)
                              }
                            },
                            [
                              _c("a-date-picker", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.chequeDate.decorator,
                                    expression: "formRules.chequeDate.decorator"
                                  }
                                ],
                                attrs: {
                                  format: _vm.DEFAULT_DATE_FORMAT,
                                  placeholder: _vm.$t(
                                    _vm.formRules.chequeDate.placeholder
                                  ),
                                  disabled: _vm.disabledForm
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.apPaymentNumber.label)
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.apPaymentNumber.decorator,
                                expression:
                                  "formRules.apPaymentNumber.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.apPaymentNumber.name,
                              placeholder: _vm.$t(
                                _vm.formRules.apPaymentNumber.placeholder
                              ),
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.description.label)
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "extra",
                              fn: function() {
                                return [
                                  _c("character-length", {
                                    attrs: {
                                      value:
                                        _vm.form.getFieldValue("description") ||
                                        ""
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("a-textarea", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.description.decorator,
                                expression: "formRules.description.decorator"
                              }
                            ],
                            attrs: {
                              rows: 4,
                              name: _vm.formRules.description.name,
                              placeholder: _vm.$t(
                                _vm.formRules.description.placeholder
                              ),
                              disabled: _vm.disabledForm
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.status.label) }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.status.decorator,
                                expression: "formRules.status.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.formRules.status.name,
                              placeholder: _vm.$t(
                                _vm.formRules.status.placeholder
                              ),
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm.detail.journalNumber && _vm.journalId
                        ? _c(
                            "a-form-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.journalNumber.label)
                              }
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "generaljournal.detail",
                                      params: { id: _vm.journalId }
                                    },
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c("a-button", { attrs: { type: "link" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.detail.journalNumber) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-tabs",
            {
              staticClass: "mt-3",
              attrs: {
                "default-active-key": "Details",
                activeKey: "Details",
                type: "card"
              }
            },
            [
              _c(
                "a-tab-pane",
                {
                  key: "Details",
                  staticClass: "bordertabs p-3",
                  attrs: { tab: "Details" }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled:
                                      _vm.isActualNew ||
                                      _vm.isDynamicStatusUpdate
                                        ? false
                                        : true
                                  },
                                  on: { click: _vm.handleAddRow }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_add_row")) + " "
                                  )
                                ]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "danger",
                                    disabled:
                                      _vm.isActualNew ||
                                      _vm.isDynamicStatusUpdate
                                        ? false
                                        : true
                                  },
                                  on: { click: _vm.confirmDeleteRow }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_delete_row")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-table",
                            {
                              attrs: {
                                dataSource: _vm.dataSource,
                                columns: _vm.columnsTable,
                                "row-selection": {
                                  selectedRowKeys: _vm.selectedRowKeys,
                                  onChange: _vm.onSelectChange
                                },
                                size: "small",
                                pagination: {
                                  showTotal: function(total) {
                                    return _vm.$t("lbl_total_items", {
                                      total: total
                                    })
                                  }
                                },
                                scroll: { y: 700, x: "calc(100% + 500px)" }
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "number",
                                  fn: function(text) {
                                    return _c("span", {}, [
                                      _vm._v(_vm._s(_vm._f("qty")(text)))
                                    ])
                                  }
                                },
                                {
                                  key: "date",
                                  fn: function(text) {
                                    return _c("span", {}, [
                                      _vm._v(_vm._s(_vm._f("date")(text)))
                                    ])
                                  }
                                },
                                {
                                  key: "nullable",
                                  fn: function(text) {
                                    return _c("span", {}, [
                                      _vm._v(_vm._s(text || "-"))
                                    ])
                                  }
                                },
                                {
                                  key: "invoiceNumber",
                                  fn: function(text, record) {
                                    return [
                                      _vm.form.getFieldValue("invoiceType") !==
                                      "Prepayment"
                                        ? _c("select-invoice-ap", {
                                            staticClass: "w-100",
                                            attrs: {
                                              value: record.invoiceApId,
                                              "branch-id": _vm.form.getFieldValue(
                                                "branchId"
                                              ),
                                              "currency-code": _vm.form.getFieldValue(
                                                "currency"
                                              ),
                                              "supplier-id": _vm.form.getFieldValue(
                                                "supplierId"
                                              ),
                                              disabled:
                                                !_vm.form.getFieldValue(
                                                  "branchId"
                                                ) ||
                                                !_vm.form.getFieldValue(
                                                  "currency"
                                                ) ||
                                                !_vm.form.getFieldValue(
                                                  "supplierId"
                                                ) ||
                                                record.disabledSelect
                                            },
                                            on: {
                                              change: function(e) {
                                                return _vm.onChangeInvoiceNumber(
                                                  e,
                                                  "invoiceAP",
                                                  record
                                                )
                                              }
                                            }
                                          })
                                        : _c("select-invoice-prepayment", {
                                            staticClass: "w-100",
                                            attrs: {
                                              value:
                                                record.invoiceApPrepaymentId,
                                              "branch-id": _vm.form.getFieldValue(
                                                "branchId"
                                              ),
                                              "currency-code": _vm.form.getFieldValue(
                                                "currency"
                                              ),
                                              "supplier-id": _vm.form.getFieldValue(
                                                "supplierId"
                                              ),
                                              disabled:
                                                !_vm.form.getFieldValue(
                                                  "branchId"
                                                ) ||
                                                !_vm.form.getFieldValue(
                                                  "currency"
                                                ) ||
                                                !_vm.form.getFieldValue(
                                                  "supplierId"
                                                ) ||
                                                record.disabledSelect
                                            },
                                            on: {
                                              change: function(e) {
                                                return _vm.onChangeInvoiceNumber(
                                                  e,
                                                  "invoicePrepayment",
                                                  record
                                                )
                                              }
                                            }
                                          })
                                    ]
                                  }
                                },
                                {
                                  key: "paymentAmount",
                                  fn: function(text, record) {
                                    return _c("a-input-number", {
                                      staticClass: "w-100",
                                      attrs: {
                                        value: record.paymentAmount,
                                        formatter: _vm.formatterNumber,
                                        parser: _vm.reverseFormatNumber,
                                        precision: _vm.storeBaseDecimalPlace,
                                        disabled: record.disabledInputNumber
                                      },
                                      on: {
                                        change: function(e) {
                                          return (record.paymentAmount = e)
                                        }
                                      }
                                    })
                                  }
                                },
                                {
                                  key: "writeOffAmount",
                                  fn: function(text, record) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm._f("currency")(text)))
                                      ]),
                                      _c("a-divider", {
                                        attrs: { type: "vertical" }
                                      }),
                                      _c("a-button", {
                                        attrs: { icon: "edit", size: "small" },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleAddData({
                                              data: record,
                                              columnName: "writeOffAmount"
                                            })
                                          }
                                        }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "invoiceDebitMemo",
                                  fn: function(text, record) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm._f("currency")(text)))
                                      ]),
                                      _c("a-divider", {
                                        attrs: { type: "vertical" }
                                      }),
                                      _c("a-button", {
                                        attrs: { icon: "edit", size: "small" },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleAddData({
                                              data: record,
                                              columnName: "invoiceDebitMemo"
                                            })
                                          }
                                        }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "additionalAmount",
                                  fn: function(text, record) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm._f("currency")(text)))
                                      ]),
                                      _c("a-divider", {
                                        attrs: { type: "vertical" }
                                      }),
                                      _c("a-button", {
                                        attrs: { icon: "edit", size: "small" },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleAddData({
                                              data: record,
                                              columnName: "additionalAmount"
                                            })
                                          }
                                        }
                                      })
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "template",
                                { slot: "footer" },
                                [
                                  _c(
                                    "a-row",
                                    {
                                      staticClass:
                                        "flex-column align-items-md-end",
                                      attrs: { type: "flex" }
                                    },
                                    [
                                      _c("a-col", { attrs: { span: 10 } }, [
                                        _c(
                                          "table",
                                          {
                                            staticClass: "footer-table",
                                            attrs: {
                                              "aria-describedby": "footer table"
                                            }
                                          },
                                          [
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "400"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl_total_invoice_amount"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.footer
                                                          .totalInvoiceAmount
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "400"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl_total_payment_amount"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.footer
                                                          .totalPaymentAmount
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "400"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl_total_additional_amount"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.footer
                                                          .totalAdditionalAmount
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "400"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl_total_write_off_and_debit_memo_account"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.footer
                                                          .totalWriteOffDebitMemo
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "400"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "lbl_total_outstanding_amount"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("currency")(
                                                        _vm.footer
                                                          .totalOutstandingAmount
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ])
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-divider"),
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleBack } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                      ]),
                      _vm.isActualApproved || (_vm.id && _vm.isActualNew)
                        ? _c(
                            "a-button",
                            {
                              attrs: { loading: _vm.loading.print },
                              on: { click: _vm.handlePrint }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                          )
                        : _vm._e(),
                      ((_vm.id && _vm.isActualNew) || _vm.isActualApproved) &&
                      _vm.$can("update", "ap-payment")
                        ? [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: _vm.$t("pop_confirmation"),
                                  "ok-text": _vm.$t("lbl_yes"),
                                  "cancel-text": _vm.$t("lbl_no")
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.onSubmit("void")
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      loading: _vm.loading.loadingSubmit
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_void")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      (!_vm.id || _vm.isDynamicStatusUpdate) &&
                      (_vm.$can("update", "ap-payment") ||
                        _vm.$can("create", "ap-payment"))
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.loadingSubmit
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit("submit")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                          )
                        : _vm._e(),
                      _vm.isActualNeedApproval &&
                      !_vm.isDynamicStatusUpdate &&
                      _vm.$can("update", "ap-payment")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.loadingSubmit
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit("update")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_update")) + " ")]
                          )
                        : _vm._e(),
                      _vm.allowApply && _vm.$can("update", "ap-payment")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.loadingSubmit
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit("apply")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_apply")) + " ")]
                          )
                        : _vm._e(),
                      _vm.isActualNeedApproval &&
                      !_vm.isDynamicStatusUpdate &&
                      _vm.$can("update", "ap-payment")
                        ? [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: {
                                  title: _vm.$t("pop_confirmation"),
                                  "ok-text": _vm.$t("lbl_yes"),
                                  "cancel-text": _vm.$t("lbl_no")
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.onSubmit("reject")
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      loading: _vm.loading.loadingSubmit
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_reject")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.isActualNeedApproval &&
                      !_vm.isDynamicStatusUpdate &&
                      _vm.$can("update", "ap-payment")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.loadingSubmit
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit("approve")
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_approve")) + " ")]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 800,
            title: _vm.title,
            showmodal: _vm.visible,
            idmodal: "modal1"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c(
                "a-space",
                { staticClass: "mb-2" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        disabled:
                          _vm.isActualNew || _vm.isDynamicStatusUpdate
                            ? false
                            : true
                      },
                      on: { click: _vm.handleAddRowModal }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_add_row")))]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "danger",
                        disabled:
                          _vm.isActualNew || _vm.isDynamicStatusUpdate
                            ? false
                            : true
                      },
                      on: { click: _vm.handleDeleteRowModal }
                    },
                    [_vm._v(_vm._s(_vm.$t("lbl_delete_row")))]
                  )
                ],
                1
              ),
              _c("TableCustom", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.title === "Invoice Debit Memo",
                    expression: "title === 'Invoice Debit Memo'"
                  }
                ],
                attrs: {
                  idtable: "table2",
                  dataSource: _vm.dataSourceModalInvoiceDebitMemo,
                  columns: _vm.columnsTableModalInvoiceDebitMemo,
                  scroll: { y: 520, x: 1000 },
                  paginationcustom: false,
                  defaultPagination: true,
                  handleSearchSelectTable: _vm.handleSearchSelectTableModal,
                  handleInputNumber: _vm.handleInputModal,
                  handleSelect: _vm.handleSelectModal,
                  onSelectChange: _vm.onSelectChangeModal,
                  selectedRowKeys: _vm.selectedRowKeysModalInvoiceDebit
                }
              }),
              _c("a-table", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.title === "Write Off Amount" ||
                      _vm.title === "Additional Amount",
                    expression:
                      "title === 'Write Off Amount' || title === 'Additional Amount'"
                  }
                ],
                attrs: {
                  dataSource: _vm.dataSourceModalWriteOffAndAdditional,
                  columns: _vm.columnsTableModalWriteOffAndAdditional,
                  scroll: { y: 520, x: 700 },
                  "row-selection": {
                    selectedRowKeys:
                      _vm.title === "Write Off Amount"
                        ? _vm.selectedRowKeysModalWriteOff
                        : _vm.title === "Additional Amount"
                        ? _vm.selectedRowKeysModalAdditionalAmount
                        : [],
                    onChange: _vm.onSelectChangeModal
                  },
                  pagination: {
                    showTotal: function(total) {
                      return _vm.$t("lbl_total_items", { total: total })
                    }
                  },
                  size: "small"
                },
                scopedSlots: _vm._u([
                  {
                    key: "amount",
                    fn: function(text, record) {
                      return _c("a-input-number", {
                        staticClass: "w-100",
                        attrs: {
                          value: record.amount,
                          formatter: _vm.formatterNumber,
                          parser: _vm.reverseFormatNumber,
                          min: 0,
                          precision: _vm.storeBaseDecimalPlace
                        },
                        on: {
                          change: function(e) {
                            return _vm.onChangeWriteOffAmount(e, record)
                          }
                        }
                      })
                    }
                  },
                  {
                    key: "accountId",
                    fn: function(text, record) {
                      return _c("coa-select", {
                        staticClass: "w-100",
                        attrs: {
                          value: record.accountId,
                          "search-by": "isParent~false"
                        },
                        on: {
                          change: function(e) {
                            return _vm.onChangeWriteOffCoa(e, record)
                          }
                        }
                      })
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                { key: "back", on: { click: _vm.handleCancelModal } },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
              ),
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: {
                    type: "primary",
                    disabled:
                      _vm.isActualNew || _vm.isDynamicStatusUpdate
                        ? false
                        : true
                  },
                  on: { click: _vm.saveDataModal }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }













































































































































































































































































































































































































































































































































































































































































































































import { SearchBuilder } from "@/builder";
import currencyFilter from "@/filters/currency.filter";
import { debounce } from "@/helpers/debounce";
import {
  useCalculator,
  useContactListFindById,
  useDisabledFromTomorrow,
  useInvoiceAP,
  useInvoiceAPPrepayment,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { OptionModel } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import {
  Amount,
  DataResponseListApPaymentMethod,
  InvoiceDebitMemo,
  RequestApPayment,
  ResponseDetailApPayment,
} from "@/models/interface/apPayment.interface";
import {
  BankDataList,
  ContactData,
  ResponseContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataResponseCompanyBank } from "@/models/interface/master.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { invoiceApPrepayment } from "@/services/InvoiceApPrepayment.service";
import { apPaymentServices } from "@/services/apPayment.service";
import { contactServices } from "@/services/contact.service";
import { generalJournalServices } from "@/services/generaljournal.service";
import { masterServices } from "@/services/master.service";
import { settingsServices } from "@/services/settings.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import Decimal from "decimal.js-light";
import moment from "moment";
import printJs from "print-js";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import APPaymentsSelectInvoiceAP from "./APPaymentsSelectInvoiceAP.vue";
import APPaymentsSelectInvoicePrepayment from "./APPaymentsSelectInvoicePrepayment.vue";

interface Row {
  apPaymentLineId: string;
  id: string;
  key: number;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceAmount: number;
  paidAmount: number;
  writeOffAmount: number;
  additionalAmount: number;
  invoiceDebitMemo: number;
  debitMemoAmount: number;
  paymentAmount: number;
  outstandingAmount: number;
  invoiceApId: string;
  invoiceApPrepaymentId: string;
  writeOffAmounts: Amount[];
  additionalAmounts: Amount[];
  invoiceDebitMemos: InvoiceDebitMemo[];
}

enum EnumStatusApPayment {
  NEED_APPROVAL = "Need Approval",
  Approved = "Approved",
  Rejected = "Rejected",
  Void = "Void",
  Settled = "Settled",
  // additional from frontend
  New = "New",
  Update = "Update",
}

type SubmitType = "void" | "submit" | "update" | "reject" | "approve" | "apply";
type PaymentType = "Unidentified" | "Applied";

@Component({
  components: {
    "select-invoice-ap": APPaymentsSelectInvoiceAP,
    "select-invoice-prepayment": APPaymentsSelectInvoicePrepayment,
  },
  mixins: [MNotificationVue],
  computed: {
    ...mapState({
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  watch: {
    "$store.state.preferenceStore.dataPreference": {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue.length > 0) {
          this.setBaseCurrencyCreate();
        }
      },
    },
    mode: {
      immediate: true,
      deep: true,
      handler() {
        if (this.$store.state.preferenceStore.dataPreference.length > 0) {
          this.setBaseCurrencyCreate();
        }
      },
    },
    currencyFrom: {
      immediate: true,
      deep: true,
      handler(newVal) {
        const params: RequestQueryParamsModel = {
          search: `fromCurrency.currencyCode~${newVal}_AND_toCurrency.currencyCode~${this.currencyTo}`,
        };
        settingsServices.listOfCurrency(params, "").then(response => {
          if (response.data[0]) {
            if (this.detail.currencyCode === newVal)
              this.setDataForm({ rate: this.detail.rates });
            else this.setDataForm({ rate: response.data[0].rate });
          } else if (newVal === this.currencyTo) {
            this.setDataForm({ rate: 1 });
          } else {
            this.setDataForm({ rate: 0 });
          }
        });
      },
    },
    detail: {
      immediate: true,
      deep: true,
      handler() {
        this.handleDisabledForm();
      },
    },
    dynamicStatus: {
      handler() {
        this.handleDisabledForm();
      },
    },
  },
})
export default class CreateApPayments extends Vue {
  useDisabledFromTomorrow = useDisabledFromTomorrow;
  formatterNumber = formatterNumber;
  reverseFormatNumber = reverseFormatNumber;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  debounce = debounce;
  form!: WrappedFormUtils;
  journalId = "";
  mode: Mode = Mode.CREATE;
  id = "";
  page = 1;
  params: RequestQueryParamsModel = {};
  loading = {
    loadingBranch: false,
    loadingFind: false,
    loadingSupplierName: false,
    loadingBankNumber: false,
    loadingDownload: false,
    loadingCurrency: false,
    loadingPaymentMethod: false,
    loadingInvoiceType: false,
    loadingBankSupplierNumber: false,
    loadingSubmit: false,
    print: false,
  };
  footer = {
    totalAdditionalAmount: 0,
    totalInvoiceAmount: 0,
    totalPaymentAmount: 0,
    totalWriteOffDebitMemo: 0,
    totalOutstandingAmount: 0,
  };

  selectedRowKeys: number[] = [];
  selectedRowKeysModalInvoiceDebit: number[] = [];
  selectedRowKeysModalWriteOff: number[] = [];
  selectedRowKeysModalAdditionalAmount: number[] = [];
  dataSupplierName: ContactData[] = [];
  dataSource: Row[] = [];
  dataInvoiceType: ResponseListMaster[] = [];
  dataBankSupplierNumber: BankDataList[] = [];
  dataBankNumber: DataResponseCompanyBank[] = [];
  dataCurrency: DataMasterCurrency[] = [];
  dataPaymentMethod: DataResponseListApPaymentMethod[] = [];
  deleteApPaymentLines: string[] = [];

  dataSourceModalInvoiceDebitMemo: InvoiceDebitMemo[] = [];
  dataSourceModalWriteOffAndAdditional: Amount[] = [];
  columnsTableModalWriteOffAndAdditional = [
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      key: "amount",
      scopedSlots: { customRender: "amount" },
    },
    {
      title: this.$t("lbl_account"),
      dataIndex: "accountId",
      key: "accountId",
      scopedSlots: { customRender: "accountId" },
    },
  ];
  columnsTableModalInvoiceDebitMemo = [
    {
      title: this.$t("lbl_invoice_debit_memo"),
      dataIndex: "invoiceDebitMemoId",
      key: "invoiceDebitMemoId",
      scopedSlots: { customRender: "invoiceDebitMemoId" },
      responsiveColSelect: [
        {
          name: "invoiceDebitMemoId",
          placeholder: this.$t("lbl_invoice_debit_memo"),
          style: "width: 100%;",
          disabled: false,
          value: "id",
          options: [] as OptionModel[],
          loading: false,
        },
      ],
      responsiveColInputNumber: [
        {
          name: "amount",
          placeholder: this.$t("lbl_amount"),
          style: "width: 100%",
          disabled: true,
        },
      ],
    },
    {
      title: this.$t("lbl_invoice_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      scopedSlots: { customRender: "isDate" },
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "amount",
      key: "amount",
      scopedSlots: { customRender: "amount" },
    },
  ];

  visible = false;
  disabledForm = false;
  currencyFrom = "IDR";
  currencyTo = "";
  title: "Invoice Debit Memo" | "Write Off Amount" | "Additional Amount" =
    "Invoice Debit Memo";
  activeKey = 0;
  // static status get from detail ap payment
  detail: Partial<ResponseDetailApPayment> = {
    status: EnumStatusApPayment.New,
    paymentType: "Unidentified",
  };
  // dynamic status can change according to conditions
  dynamicStatus = EnumStatusApPayment.New;

  columnsTable = [
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      scopedSlots: { customRender: "invoiceNumber" },
    },
    {
      title: this.$t("lbl_ap_payment_date"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_invoice_amount"),
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_paid_amount"),
      dataIndex: "paidAmount",
      key: "paidAmount",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_write_off_amount"),
      dataIndex: "writeOffAmount",
      key: "writeOffAmount",
      scopedSlots: { customRender: "writeOffAmount" },
    },
    {
      title: this.$t("lbl_invoice_debit_memo"),
      dataIndex: "invoiceDebitMemo",
      key: "invoiceDebitMemo",
      scopedSlots: { customRender: "invoiceDebitMemo" },
    },
    {
      title: this.$t("lbl_additional_amount"),
      dataIndex: "additionalAmount",
      key: "additionalAmount",
      scopedSlots: { customRender: "additionalAmount" },
    },
    {
      title: this.$t("lbl_payment_amount"),
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      scopedSlots: { customRender: "paymentAmount" },
    },
    {
      title: this.$t("lbl_outstanding_amount"),
      dataIndex: "outstandingAmount",
      key: "outstandingAmount",
      customRender: this.countOutstandingAmount,
    },
  ];

  formRules = {
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branchId",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    apPaymentNumber: {
      label: "lbl_ap_payment_number",
      name: "apPaymentNumber",
      placeholder: "lbl_ap_payment_number_placeholder",
      decorator: ["aPPaymentNumber"],
    },
    invoiceType: {
      label: "lbl_invoice_type",
      name: "invoiceType",
      placeholder: "lbl_invoice_type_placeholder",
      decorator: [
        "invoiceType",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    apPaymentType: {
      label: "lbl_ap_payment_type",
      name: "apPaymentType",
      placeholder: "lbl_ap_payment_type_placeholder",
      decorator: [
        "apPaymentType",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierName: {
      label: "lbl_supplier_name",
      name: "supplierName",
      placeholder: "lbl_supplier_name_placeholder",
      decorator: [
        "supplierId",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierBillingTo: {
      label: "lbl_supplier_billing_to",
      name: "supplierBillingTo",
      placeholder: "lbl_supplier_billing_to_placeholder",
      decorator: [
        "supplierBillingTo",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    journalNumber: {
      label: "lbl_journal_number",
    },
    apPaymentDate: {
      label: "lbl_ap_payment_date",
      name: "apPaymentDate",
      placeholder: "lbl_ap_payment_date_placeholder",
      decorator: [
        "apPaymentDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    purchaseOrderNumber: {
      label: "lbl_purchase_order_number",
      name: "purchaseOrderNumber",
      placeholder: "lbl_purchase_order_number_placeholder",
      decorator: [
        "purchaseOrderNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency_placeholder",
      decorator: [
        "currency",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    paymentMethod: {
      label: "lbl_payment_method",
      name: "paymentMethod",
      placeholder: "lbl_payment_method_placeholder",
      decorator: [
        "paymentMethod",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    rate: {
      label: "lbl_rate",
      name: "rate",
      placeholder: "lbl_rate_placeholder",
      decorator: [
        "rate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    paymentAmount: {
      label: "lbl_payment_amount",
      name: "paymentAmount",
      placeholder: "lbl_payment_amount_placeholder",
      decorator: [
        "paymentAmount",
        {
          type: "number",
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    bankName: {
      label: "lbl_bank_name",
      name: "bankName",
      placeholder: "lbl_bank_name_placeholder",
      decorator: [
        "bankName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    bankNumber: {
      label: "lbl_bank_number",
      name: "bankNumber",
      placeholder: "lbl_bank_number_placeholder",
      decorator: [
        "bankNumber",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    chequeNumber: {
      label: "lbl_cheque_number",
      name: "chequeNumber",
      placeholder: "lbl_cheque_number_placeholder",
      decorator: [
        "chequeNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    chequeDate: {
      label: "lbl_cheque_date",
      name: "chequeDate",
      placeholder: "lbl_cheque_date_placeholder",
      decorator: [
        "chequeDate",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    bankSupplierNumber: {
      label: "lbl_bank_supplier_number",
      name: "bankSupplierNumber",
      placeholder: "lbl_bank_supplier_number_placeholder",
      decorator: [
        "bankSupplierNumber",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    accountBankSupplierName: {
      label: "lbl_account_bank_supplier_name",
      name: "accountBankSupplierName",
      placeholder: "lbl_account_bank_supplier_name_placeholder",
      decorator: [
        "accountBankSupplierName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    description: {
      label: "lbl_description",
      name: "status",
      placeholder: "lbl_description",
      decorator: [
        "description",
        {
          rules: [
            {
              max: 1000,
            },
          ],
        },
      ],
    },
    status: {
      label: "lbl_status",
      name: "status",
      placeholder: "lbl_status_placeholder",
      decorator: ["status"],
    },
  };

  show = {
    chequeNumber: false,
  };

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "createAPPayment" });
  }

  created() {
    this.getInvoiceType();
    this.getSupplierName("", "init");
    this.getCurrency("");
    this.getPaymentMethod("");
    this.getBankNumber();
    this.mode = this.$route.meta.mode;
    this.id = this.$route.params.id;
    if (this.mode === Mode.VIEW || this.mode === Mode.EDIT) {
      this.getDetailApPayment(this.id);
    } else {
      this.setDataForm({
        apPaymentType: "Unidentified",
      });
    }
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }

  get isActualNeedApproval(): boolean {
    return this.detail.status === EnumStatusApPayment.NEED_APPROVAL;
  }
  get isActualApproved(): boolean {
    return this.detail.status === EnumStatusApPayment.Approved;
  }
  get isActualRejected(): boolean {
    return this.detail.status === EnumStatusApPayment.Rejected;
  }
  get isActualVoid(): boolean {
    return this.detail.status === EnumStatusApPayment.Void;
  }
  get isActualSettled(): boolean {
    return this.detail.status === EnumStatusApPayment.Settled;
  }
  get isActualNew(): boolean {
    return this.detail.status === EnumStatusApPayment.New;
  }
  get isDynamicStatusNeedApproval(): boolean {
    return this.dynamicStatus === EnumStatusApPayment.NEED_APPROVAL;
  }
  get isDynamicStatusApproved(): boolean {
    return this.dynamicStatus === EnumStatusApPayment.Approved;
  }
  get isDynamicStatusRejected(): boolean {
    return this.dynamicStatus === EnumStatusApPayment.Rejected;
  }
  get isDynamicStatusVoid(): boolean {
    return this.dynamicStatus === EnumStatusApPayment.Void;
  }
  get isDynamicStatusSettled(): boolean {
    return this.dynamicStatus === EnumStatusApPayment.Settled;
  }
  get isDynamicStatusNew(): boolean {
    return this.dynamicStatus === EnumStatusApPayment.New;
  }
  get isDynamicStatusUpdate(): boolean {
    return this.dynamicStatus === EnumStatusApPayment.Update;
  }

  get allowApply(): boolean {
    const status = [EnumStatusApPayment.New];
    return (
      status.includes(this.detail.status as EnumStatusApPayment) && !!this.id
    );
  }

  @Watch("dataSource", { immediate: true, deep: true })
  onChangeDataSource(): void {
    if (!this.dataSource.length) return;
    const { sum } = useCalculator();

    this.footer.totalInvoiceAmount = sum(
      this.dataSource.map<number>(item => item.invoiceAmount)
    );

    this.footer.totalAdditionalAmount = sum(
      this.dataSource.map<number>(item => item.additionalAmount)
    );

    this.footer.totalPaymentAmount = sum(
      this.dataSource.map<number>(item => item.paymentAmount)
    );

    //#region sum total writeoff debit memo

    const sumWriteOffAmount = sum(
      this.dataSource.map<number>(item => item.writeOffAmount)
    );

    // sum total invoice debit memo
    const sumInvoiceDebitMemo = sum(
      this.dataSource.map<number>(item => item.invoiceDebitMemo)
    );

    this.footer.totalWriteOffDebitMemo = new Decimal(sumWriteOffAmount)
      .add(sumInvoiceDebitMemo)
      .toNumber();

    //#endregion

    if (this.isActualNew || this.isActualNeedApproval) {
      this.footer.totalOutstandingAmount = this.dataSource.reduce(
        (prev, curr) => {
          return new Decimal(prev || 0)
            .plus(curr.invoiceAmount || 0)
            .minus(curr.paidAmount || 0)
            .minus(curr.writeOffAmount || 0)
            .minus(curr.debitMemoAmount || 0)
            .minus(curr.paymentAmount || 0)
            .toNumber();
        },
        0
      );
    }
  }

  handleDisabledForm() {
    /**
     * isDynamicStatusNeedApproval
     * isDynamicStatusApproved
     * isDynamicStatusRejected
     * isDynamicStatusVoid
     * isDynamicStatusSettled
     * isDynamicStatusNew
     * isDynamicStatusUpdate
     */
    this.disabledForm = !!this.id;
  }

  countOutstandingAmount(_value: unknown, row: Row): unknown {
    if (this.isActualNew || this.isActualNeedApproval) {
      const outstandingAmount = new Decimal(row.invoiceAmount || 0)
        .minus(row.paidAmount || 0)
        .minus(row.writeOffAmount || 0)
        .minus(row.debitMemoAmount || 0)
        .minus(row.paymentAmount || 0)
        .toNumber();
      row.outstandingAmount = outstandingAmount;
      return currencyFilter(outstandingAmount);
    } else {
      return currencyFilter(row.outstandingAmount);
    }
  }

  setDataForm(values): void {
    for (const key in values) {
      this.form.getFieldDecorator(key, {
        initialValue: values[key],
      });
    }
    this.form.setFieldsValue(values);
  }

  handleChangePaymentMethod(value: string): void {
    this.form.setFieldsValue({ paymentMethod: value });
    this.getBankNumber();
    this.form.resetFields(["bankNumber"]);
    this.show.chequeNumber = value?.toLowerCase() === "cheque";
    if (value.toLowerCase() === "cheque") {
      this.setFieldRequired("chequeNumber", true);
      this.setFieldRequired("chequeDate", true);
    } else {
      this.setFieldRequired("chequeNumber", false);
      this.setFieldRequired("chequeDate", false);
    }
  }

  handleChangeBranch(branchId: string): void {
    this.setDataForm({ branchId });
    this.dataSource = [];

    this.getListInvoiceApPrepaymentDebitMemo("");
  }

  handleChangeSupplier(idSupplier: string | undefined): void {
    this.setDataForm({ supplierId: idSupplier });
    this.dataSource = [];
    this.getListInvoiceApPrepaymentDebitMemo("");
    if (!idSupplier) {
      this.detail.paymentType = "Unidentified";
      return;
    } else {
      this.detail.paymentType = "Applied";
    }
    this.getDetailSupplier(idSupplier);
  }

  async getDetailSupplier(idSupplier: string): Promise<void> {
    try {
      const contact = await contactServices.getContactData(idSupplier);
      this.setListSupplierBank(contact);
      this.setDefaultSupplierBillingTo(contact);
    } catch (error) {
      this.showNotifError("notif_process_fail");
    }
  }

  setListSupplierBank(contact: ResponseContactData): void {
    this.dataBankSupplierNumber = contact.bankDataList;
  }

  setDefaultSupplierBillingTo(contact: ResponseContactData): void {
    const { addressDataList } = contact;
    const address =
      addressDataList.find(item => item.primaryBillTo)?.address || "";
    this.form.setFieldsValue({ supplierBillingTo: address });
  }

  handleChangeSupplierBank(accNumber: string): void {
    const bank = this.dataBankSupplierNumber.find(
      item => item.bankAccNumber === accNumber
    );
    if (!bank) return;
    this.form.setFieldsValue({ accountBankSupplierName: bank.bankAccName });
  }

  handleChangeCurrency(currencyCode: string): void {
    this.setDataForm({ currency: currencyCode });
    this.currencyFrom = currencyCode;
    this.getBankNumber();
    this.dataSource = [];

    this.getListInvoiceApPrepaymentDebitMemo("");

    this.form.resetFields(["bankNumber"]);
  }

  handleInput(value, key, _objectColInput, objectColName, onEvent): void {
    if (objectColName === "paymentAmount") {
      if (onEvent === "onFocus") {
        this.dataSource[key][objectColName] = changeCurrencytoNumeric(value);
      }
      if (onEvent === "onChange") {
        this.dataSource[key][objectColName] = value;
      }
      if (onEvent === "onBlur") {
        this.dataSource[key][objectColName] = currencyFormat(value);
      }
    } else {
      this.dataSource[key][objectColName] = value;
    }
  }

  handleInputNumber(
    value,
    key,
    _objectColInput,
    objectColName,
    _onEvent
  ): void {
    this.dataSource[key][objectColName] = value;
  }

  /**
   * @param value invoice AP id
   */
  onChangeInvoiceNumber(
    value: string,
    source: "invoiceAP" | "invoicePrepayment",
    record: Row
  ): void {
    if (source === "invoiceAP") {
      record.invoiceApId = value;
      this.autofillInvoiceAP(record);
    } else if (source === "invoicePrepayment") {
      record.invoiceApPrepaymentId = value;
      this.autofillInvoicePrepayment(record);
    }
  }

  autofillInvoicePrepayment(record: Row): void {
    const { findById } = useInvoiceAPPrepayment();
    findById(record.invoiceApPrepaymentId).then(response => {
      record.invoiceDate = response.invoiceDate;
      record.invoiceAmount = +response.total || 0;
      record.paidAmount = +response.paidAmount || 0;
    });
  }

  autofillInvoiceAP(record: Row): void {
    const { findById } = useInvoiceAP();
    findById(record.invoiceApId).then(response => {
      record.invoiceDate = response.invoiceDate;
      record.invoiceAmount = response.grandTotal || 0;
      record.paidAmount = response.paidAmount || 0;
    });
  }

  onSelectChange(value) {
    this.selectedRowKeys = value;
  }

  onSelectChangeModal(selectedRowKeys: number[]): void {
    if (this.title === "Invoice Debit Memo") {
      this.selectedRowKeysModalInvoiceDebit = selectedRowKeys;
    } else if (this.title === "Write Off Amount") {
      this.selectedRowKeysModalWriteOff = selectedRowKeys;
    } else {
      this.selectedRowKeysModalAdditionalAmount = selectedRowKeys;
    }
  }

  handleAddRow() {
    this.dataSource = [
      ...this.dataSource,
      {
        key: this.dataSource.length,
        id: "",
        apPaymentLineId: "",
        invoiceNumber: "",
        invoiceDate: "",
        invoiceAmount: 0,
        paidAmount: 0,
        writeOffAmount: 0,
        additionalAmount: 0,
        invoiceDebitMemo: 0,
        debitMemoAmount: 0,
        paymentAmount: 0,
        outstandingAmount: 0,
        invoiceApId: "",
        invoiceApPrepaymentId: "",
        writeOffAmounts: [],
        additionalAmounts: [],
        invoiceDebitMemos: [],
      },
    ];
  }

  confirmDeleteRow() {
    if (this.selectedRowKeys.length < 1) {
      this.$notification.error({
        message: "Error",
        description: "Select data first to delete",
      });
    } else {
      this.$confirm({
        title: Messages.CONFIRM_DELETE_TITLE,
        content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
        onOk: this.handleDeleteRow,
        onCancel() {
          return;
        },
      });
    }
  }

  handleDeleteRow(): void {
    this.dataSource = this.dataSource.filter(data => {
      if (this.selectedRowKeys.includes(data.key))
        this.deleteApPaymentLines.push(data.id);
      return !this.selectedRowKeys.includes(data.key);
    });
    this.deleteApPaymentLines = this.deleteApPaymentLines.filter(
      dataFilter => dataFilter !== ""
    );
    this.dataSource.forEach((data, index) => (data.key = index));
    this.dataSource = this.dataSource.slice();
    this.selectedRowKeys = [];
  }

  async findJournalByNumber(journalNumber: string | undefined): Promise<void> {
    try {
      if (!journalNumber) return;
      const { data } = await generalJournalServices.listGeneralJournal({
        search: `name~${journalNumber}`,
      });
      const [item] = data;
      if (!item) return;
      this.journalId = item.id;
    } catch (error) {
      this.showNotifError("notif_process_fail");
    }
  }

  handleBack() {
    this.$confirm({
      title: this.$t("lbl_leave_page"),
      onOk: () => {
        this.$router.push("/account-payables/ap-payments/list");
      },
      onCancel() {
        return;
      },
    });
  }

  setBaseCurrencyCreate() {
    const tempObject = {
      feature_base_currency: "",
    };
    this.$store.state.preferenceStore.dataPreference.forEach(dataForeach => {
      if (dataForeach.key === "feature_base_currency") {
        tempObject.feature_base_currency = dataForeach.value;
      }
    });
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (tempObject.feature_base_currency) {
      params.search = `secureId~${tempObject.feature_base_currency}`;
      settingsServices.listOfMasterCurrency(params, "").then(response => {
        this.currencyTo = response.data[0]?.currencyCode as string;
        if (this.mode && this.mode.toLocaleLowerCase() === Mode.CREATE) {
          this.setDataForm({ currency: response.data[0]?.currencyCode });
        }
      });
    }
  }

  handleNotif(notifSuccess, documentNo) {
    this.showNotifSuccess(notifSuccess, {
      documentNumber: documentNo,
    });
    this.$router.push("/account-payables/ap-payments/list");
  }

  onSubmitUpdate(payload) {
    this.loading.loadingSubmit = true;
    apPaymentServices
      .updateApPayment(payload, this.id)
      .then(response => {
        this.handleNotif("notif_update_success", response.documentNo);
      })
      .finally(() => (this.loading.loadingSubmit = false));
  }

  submitAppliedOrUnidentified(payload) {
    if (
      this.detail.paymentType === "Unidentified" ||
      (this.detail.paymentType === "Applied" && !this.id)
    ) {
      this.loading.loadingSubmit = true;
      apPaymentServices
        .postApPayment(payload)
        .then(response => {
          this.handleNotif("notif_create_success", response.documentNo);
        })
        .finally(() => (this.loading.loadingSubmit = false));
    } else {
      this.loading.loadingSubmit = true;
      payload.status = "Approved";
      apPaymentServices
        .appliedApPayment(payload, this.id)
        .then(response => {
          this.handleNotif("notif_approve_success", response.documentNo);
        })
        .finally(() => (this.loading.loadingSubmit = false));
    }
  }

  allValidation(): boolean {
    let tempError: string[] = [];
    this.dataSource.forEach(dataForeach => {
      const dataMustBePaid = new Decimal(dataForeach.invoiceAmount)
        .minus(dataForeach.paidAmount || 0)
        .minus(dataForeach.writeOffAmount || 0)
        .minus(dataForeach.debitMemoAmount || 0)
        .toNumber();
      if (dataForeach.paymentAmount > dataMustBePaid)
        tempError.push("Payment Overbilled");
    });
    tempError = [...new Set(tempError)];
    tempError.forEach(dataForeach => {
      this.showNotifError(dataForeach);
    });
    return tempError.length > 0 ? true : false;
  }

  onSubmit(type: SubmitType): void {
    this.form.validateFields((err, res) => {
      if (err) return;
      const payload: RequestApPayment = {
        branchId: res.branchId,
        invoiceAPType: res.invoiceType,
        supplierId: res.supplierId,
        supplierBillTo: res.supplierBillingTo,
        supplierBankNumber: res.bankSupplierNumber,
        supplierAccountBankName: res.accountBankSupplierName,
        paymentDate: moment(res.apPaymentDate).format(),
        currencyId: this.dataCurrency.find(
          dataFind => dataFind.currencyCode === res.currency
        )?.id as string,
        rates: res.currency === "IDR" ? 1 : res.rate,
        paymentMethod: res.paymentMethod,
        bankId: res.bankNumber,
        chequeNumber: res.chequeNumber ? res.chequeNumber : null,
        chequeDate: res.chequeDate ? moment(res.chequeDate).format() : null,
        status: type,
        totalInvoiceAmount: this.footer.totalInvoiceAmount,
        totalPaymentAmount: res.paymentAmount || 0,
        totalWriteOffAndDebitMemoAmount: this.footer.totalWriteOffDebitMemo,
        totalOutstandingAmount: this.footer.totalOutstandingAmount,
        paymentType: this.detail.paymentType as PaymentType,
        apPaymentLines: this.dataSource.map(dataMap => {
          return {
            id: dataMap.id,
            apPaymentLineId: dataMap.apPaymentLineId,
            invoiceApId: dataMap.invoiceApId,
            invoiceAPPrepaymentId: dataMap.invoiceApPrepaymentId,
            invoiceDate: moment(dataMap.invoiceDate).format(),
            invoiceAmount: dataMap.invoiceAmount || 0,
            paidAmount: dataMap.paidAmount || 0,
            debitMemoAmount: dataMap.debitMemoAmount || 0,
            paymentAmount: dataMap.paymentAmount || 0,
            outstandingAmount: dataMap.outstandingAmount || 0,
            invoiceDebitMemos: dataMap.invoiceDebitMemos || 0,
            writeOffAmounts: dataMap.writeOffAmounts || 0,
            additionalAmounts: dataMap.additionalAmounts || 0,
          };
        }),
        deleteApPaymentLines: this.deleteApPaymentLines,
        description: res.description,
      };
      switch (type) {
        case "submit":
          if (!this.allValidation()) {
            if (this.isDynamicStatusUpdate) {
              payload.status = "Need Approval";
              this.onSubmitUpdate(payload);
            } else {
              payload.status =
                this.detail.paymentType === "Unidentified"
                  ? "New"
                  : "Need Approval";
              this.submitAppliedOrUnidentified(payload);
            }
          }
          break;
        case "update":
          this.dynamicStatus = EnumStatusApPayment.Update;
          this.dataSource = this.dataSource.map(dataMap => {
            return {
              ...dataMap,
              disabledInput: false,
              disabledSelect: false,
              disabledInputNumber: false,
            };
          });
          break;
        case "apply":
          payload.status = "Approved";
          apPaymentServices
            .appliedApPayment(payload, this.id)
            .then(response => {
              this.showNotifSuccess("notif_approve_success", {
                documentNumber: response.documentNo,
              });
              this.$router.push("/account-payables/ap-payments/list");
            })
            .finally(() => (this.loading.loadingSubmit = false));
          break;
        case "void":
          payload.status = "Void";
          apPaymentServices
            .voidApPayment(payload, this.id)
            .then(response => {
              this.showNotifSuccess("notif_void_success", {
                documentNumber: response.documentNo,
              });
              this.$router.push("/account-payables/ap-payments/list");
            })
            .finally(() => (this.loading.loadingSubmit = false));
          break;
        case "reject":
          payload.status = "Rejected";
          this.onSubmitUpdate(payload);
          break;
        case "approve":
          payload.status = "Approved";
          this.onSubmitUpdate(payload);
          break;
        default:
          break;
      }
    });
  }

  getInvoiceType(): void {
    this.loading.loadingInvoiceType = true;
    masterServices
      .listMaster({ name: "INVOICE_AP_TYPE" })
      .then(res => {
        this.dataInvoiceType = res.filter(
          e => e.value === "Invoice AP" || e.value === "Prepayment"
        );
      })
      .finally(() => (this.loading.loadingInvoiceType = false));
  }

  getSupplierName(
    valueSearch: string | undefined,
    source: "search" | "init"
  ): void {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `supplier~true_AND_active~true`,
    };
    if (valueSearch)
      params.search = `firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*_AND_supplier~true_AND_active~true`;
    this.loading.loadingSupplierName = true;
    contactServices
      .listContactData(params)
      .then(res => {
        this.dataSupplierName = res.data;

        if (source === "init") this.handleMissingOptSupplier();
      })
      .finally(() => (this.loading.loadingSupplierName = false));
  }

  getCurrency(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch) params.search = `currencyCode~${valueSearch}`;
    this.loading.loadingCurrency = true;
    settingsServices
      .listOfMasterCurrency(params, "")
      .then(response => (this.dataCurrency = response.data))
      .finally(() => (this.loading.loadingCurrency = false));
  }

  getPaymentMethod(valueSearch) {
    const params: RequestQueryParamsModel = {};
    if (valueSearch) params.search = `paymentMethod~*${valueSearch}*`;
    this.loading.loadingPaymentMethod = true;
    apPaymentServices
      .listApPaymentMethod(params)
      .then(response => {
        this.dataPaymentMethod = response;
      })
      .finally(() => (this.loading.loadingPaymentMethod = false));
  }

  getBankNumber(valueSearch = ""): void {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const defaultQuery = builder
      .push(["currency.currencyCode", this.currencyFrom], { like: "both" })
      .build();
    const q: string[] = [defaultQuery];
    const { paymentMethod = "" } = this.form.getFieldsValue();

    builder.destroy(); // clear previous query

    if (paymentMethod.toLowerCase() === "cash") {
      q.push(
        builder.push(["companyBankType", "CASH"], { like: "both" }).build()
      );
      builder.destroy();
    } else {
      q.push(
        builder.push(["companyBankType", "BANK"], { like: "both" }).build()
      );
      builder.destroy();
    }

    if (valueSearch) {
      q.unshift(
        builder
          .push(["bankAccName", valueSearch], { like: "both" })
          .or()
          .push(["bankAccNumber", valueSearch], { like: "both" })
          .or()
          .push(["bankName", valueSearch], { like: "both" })
          .build()
      );
    }

    this.loading.loadingBankNumber = true;
    params.search = q.join(builder.AND);
    masterServices
      .listCompanyBank(params)
      .then(data => {
        this.dataBankNumber = data.data;
      })
      .finally(() => (this.loading.loadingBankNumber = false));
  }

  // Modal only
  handleSearchSelectTableModal(
    value: string,
    _rowKey: string | number,
    colName: string
  ): void {
    if (colName === "invoiceDebitMemoId") {
      this.getListInvoiceApPrepaymentDebitMemo(value);
    }
  }

  handleInputModal(value, key, _objectColInput, colName) {
    if (this.title === "Invoice Debit Memo") {
      this.dataSourceModalInvoiceDebitMemo[key][colName] = value;
      this.dataSourceModalInvoiceDebitMemo =
        this.dataSourceModalInvoiceDebitMemo.slice();
    }
  }
  handleSelectModal(value, key, _colName): void {
    if (this.title === "Invoice Debit Memo") {
      const findColumn = this.columnsTableModalInvoiceDebitMemo.find(
        c => c.responsiveColSelect
      )?.responsiveColSelect;

      if (findColumn && value) {
        const findData = findColumn[0].options.find(
          dataFind => dataFind.id === value
        );
        this.dataSourceModalInvoiceDebitMemo[key]["invoiceDebitMemoId"] = value;
        this.dataSourceModalInvoiceDebitMemo[key]["invoiceDebitMemoNumber"] =
          findData?.documentNumber || "";
        this.dataSourceModalInvoiceDebitMemo[key]["invoiceDate"] =
          findData?.invoiceDate || "";
        this.dataSourceModalInvoiceDebitMemo[key]["amount"] =
          findData?.amount || 0;
      } else {
        this.dataSourceModalInvoiceDebitMemo[key]["invoiceDebitMemoId"] = "";
        this.dataSourceModalInvoiceDebitMemo[key]["invoiceDebitMemoNumber"] =
          "";
        this.dataSourceModalInvoiceDebitMemo[key]["invoiceDate"] = "";
        this.dataSourceModalInvoiceDebitMemo[key]["amount"] = 0;
      }
    }
  }

  /**
   * @description find invoice ap prepayment options in modal invoice debit memo
   */
  getListInvoiceApPrepaymentDebitMemo(valueSearch?: string): void {
    const { supplierId, branchId, currency } = this.form.getFieldsValue();
    if (!branchId || !supplierId || !currency) return;

    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      sorts: "createdDate:desc",
      search: `branchWarehouse.secureId~${branchId}_AND_supplier.secureId~*${supplierId}*_AND_invoiceType~*Debit Memo*_AND_priceCurrency.currencyCode~*${currency}*`,
    };
    if (valueSearch) {
      params.search = `documentNumber~*${valueSearch}*_AND_${params.search}`;
    }

    invoiceApPrepayment.getListInvoiceApPrepayment(params).then(response => {
      const findColumnModal = this.columnsTableModalInvoiceDebitMemo.find(
        c => c.responsiveColSelect
      )?.responsiveColSelect;

      if (!findColumnModal) return;
      findColumnModal[0].options = response.data.map((dataMap, index) => ({
        ...dataMap,
        invoiceDate: dataMap.invoiceDate,
        amount: dataMap.amount,
        name: dataMap.documentNumber,
        key: index,
      }));
    });
  }

  filterDuplicateWithSameId(dataArray) {
    return dataArray.filter(
      (value, index, self) => index === self.findIndex(t => t.id === value.id)
    );
  }

  assignDataBankNumber(response: ResponseDetailApPayment) {
    const partial = (
      dataBankNumber: DataResponseCompanyBank[],
      fieldsUpdate: Partial<DataResponseCompanyBank>
    ) => {
      return [...dataBankNumber, fieldsUpdate] as DataResponseCompanyBank[];
    };
    this.dataBankNumber = this.filterDuplicateWithSameId(
      partial(this.dataBankNumber, {
        id: response.companyBankId,
        bankName: response.bankName,
        bankAccNumber: response.bankAccNumber,
      })
    );
  }

  getDetailApPayment(id: string): void {
    const { sum } = useCalculator();
    apPaymentServices.detailApPayment(id).then(response => {
      this.currencyFrom = response.currencyCode;
      this.detail = response;
      this.hideShowColumnTable(
        response.invoiceAPType as "Prepayment" | "Invoice AP"
      );
      this.findJournalByNumber(this.detail.journalNumber);
      this.dynamicStatus = response.status as EnumStatusApPayment;
      this.assignDataBankNumber(response);
      this.dataSource = response.invoiceApPaymentLineDTOS.map(
        (dataMap, index) => {
          return {
            id: dataMap.id,
            apPaymentLineId: dataMap.id,
            key: index,
            invoiceNumber:
              response.invoiceAPType === "Prepayment"
                ? dataMap.invoiceAPPrepaymentId
                : dataMap.invoiceAPId,
            invoiceApId: dataMap.invoiceAPId,
            invoiceApPrepaymentId: dataMap.invoiceAPPrepaymentId,
            invoiceDate: dataMap.invoiceDate,
            invoiceAmount: dataMap.invoiceAmount,
            paidAmount: dataMap.paidAmount,
            writeOffAmount: sum(
              dataMap.writeOffAmounts.map<number>(item => item.amount)
            ),
            additionalAmount: sum(
              dataMap.additionalAmounts.map<number>(item => item.amount)
            ),
            invoiceDebitMemo: sum(
              dataMap.invoiceDebitMemos.map<number>(item => item.amount)
            ),
            debitMemoAmount: sum(
              dataMap.invoiceDebitMemos.map<number>(item => item.amount)
            ),
            paymentAmount: dataMap.paymentAmount,
            outstandingAmount: dataMap.outstandingAmount,
            writeOffAmounts: dataMap.writeOffAmounts,
            additionalAmounts: dataMap.additionalAmounts,
            invoiceDebitMemos: dataMap.invoiceDebitMemos,
            disabledSelect: !this.isActualNew,
            disabledInput: !this.isActualNew,
            disabledInputNumber: !this.isActualNew,
          };
        }
      );
      this.footer.totalInvoiceAmount = response.totalInvoiceAmount;
      this.footer.totalPaymentAmount = response.totalPaymentAmount;
      this.footer.totalWriteOffDebitMemo =
        response.totalWriteOffAndDebitMemoAmount;
      this.footer.totalOutstandingAmount = response.totalOutstandingAmount;
      const value = {
        branchId: response.branchId,
        invoiceType: response.invoiceAPType,
        supplierId: response.supplierId,
        supplierBillingTo: response.supplierBillTo,
        bankSupplierNumber: response.supplierBankNumber,
        accountBankSupplierName: response.supplierAccountBankName,
        apPaymentDate: response.paymentDate
          ? moment(response.paymentDate)
          : null,
        currency: response.currencyCode,
        rate: response.rates,
        paymentMethod: response.paymentMethod,
        bankNumber: response.companyBankId,
        chequeNumber: response.chequeNumber,
        chequeDate: response.chequeDate ? moment(response.chequeDate) : null,
        status: response.status,
        aPPaymentNumber: response.documentNo,
        apPaymentType: response.paymentType,
        paymentAmount: response.totalPaymentAmount,
        description: response.description,
      };

      this.setDataForm(value);

      this.show.chequeNumber = value.paymentMethod.toLowerCase() === "cheque";

      this.handleMissingOptSupplier();

      this.getListInvoiceApPrepaymentDebitMemo();
    });
  }

  async handleMissingOptSupplier(): Promise<void> {
    try {
      const { supplierId } = this.form.getFieldsValue();
      if (!this.dataSupplierName.length || !supplierId) return;

      const found = this.dataSupplierName.find(item => item.id === supplierId);
      if (found) return;
      const res = await useContactListFindById(supplierId);
      this.dataSupplierName.push(res);
    } catch (error) {
      this.showNotifError("notif_process_fail");
    }
  }

  handleCancelModal(): void {
    this.visible = false;
    this.dataSourceModalInvoiceDebitMemo = [];
    this.dataSourceModalWriteOffAndAdditional = [];
    this.selectedRowKeysModalInvoiceDebit = [];
    this.selectedRowKeysModalWriteOff = [];
    this.selectedRowKeysModalAdditionalAmount = [];
  }

  handleAddRowModal(): void {
    if (this.title === "Invoice Debit Memo") {
      const { dataSourceModalInvoiceDebitMemo } = this;
      this.dataSourceModalInvoiceDebitMemo = [
        ...dataSourceModalInvoiceDebitMemo,
        {
          invoiceDebitMemoId: "",
          invoiceDebitMemoNumber: "",
          invoiceDate: "",
          amount: 0,
          key: this.dataSourceModalInvoiceDebitMemo.length,
        },
      ];
    } else {
      const { dataSourceModalWriteOffAndAdditional } = this;
      this.dataSourceModalWriteOffAndAdditional = [
        ...dataSourceModalWriteOffAndAdditional,
        {
          accountId: "",
          accountName: "",
          amount: 0,
          key: this.dataSourceModalWriteOffAndAdditional.length,
        },
      ];
    }
  }

  handleDeleteRowModal(): void {
    let rowKeys: number[] = [];
    if (this.title === "Invoice Debit Memo") {
      rowKeys = this.selectedRowKeysModalInvoiceDebit;
    } else if (this.title === "Write Off Amount") {
      rowKeys = this.selectedRowKeysModalWriteOff;
    } else {
      rowKeys = this.selectedRowKeysModalAdditionalAmount;
    }

    if (rowKeys.length < 1) {
      this.showNotifError("lbl_modal_delete_error_description");
      return;
    }
    this.$confirm({
      title: this.$t("lbl_modal_delete_title_confirm"),
      content: this.$t("lbl_modal_delete_info", { count: rowKeys.length }),
      onOk: () => {
        if (this.title === "Invoice Debit Memo") {
          this.dataSourceModalInvoiceDebitMemo =
            this.dataSourceModalInvoiceDebitMemo.filter(data => {
              return !rowKeys.includes(data.key);
            });
          this.dataSourceModalInvoiceDebitMemo.forEach(
            (data, index) => (data.key = index)
          );
          this.dataSourceModalInvoiceDebitMemo =
            this.dataSourceModalInvoiceDebitMemo.slice();
        } else {
          this.dataSourceModalWriteOffAndAdditional =
            this.dataSourceModalWriteOffAndAdditional.filter(data => {
              return !rowKeys.includes(data.key);
            });
          this.dataSourceModalWriteOffAndAdditional.forEach(
            (data, index) => (data.key = index)
          );
          this.dataSourceModalWriteOffAndAdditional =
            this.dataSourceModalWriteOffAndAdditional.slice();
        }
        rowKeys = [];
        if (this.title === "Invoice Debit Memo") {
          this.selectedRowKeysModalInvoiceDebit = rowKeys;
        } else if (this.title === "Write Off Amount") {
          this.selectedRowKeysModalWriteOff = rowKeys;
        } else {
          this.selectedRowKeysModalAdditionalAmount = rowKeys;
        }
      },
      onCancel() {
        return;
      },
    });
  }

  saveDataModal() {
    const tempData = this.dataSourceModalWriteOffAndAdditional;
    const tempDataDebitMemo = this.dataSourceModalInvoiceDebitMemo;
    const { sum } = useCalculator();
    switch (this.title) {
      case "Write Off Amount":
        this.dataSource[this.activeKey].writeOffAmounts = tempData;
        this.dataSource[this.activeKey].writeOffAmount = sum(
          tempData.map<number>(item => item.amount)
        );
        this.dataSourceModalWriteOffAndAdditional = [];
        this.visible = false;
        break;
      case "Invoice Debit Memo":
        this.dataSource[this.activeKey].invoiceDebitMemos = tempDataDebitMemo;
        this.dataSource[this.activeKey].invoiceDebitMemo = sum(
          tempDataDebitMemo.map<number>(item => item.amount)
        );
        this.dataSourceModalInvoiceDebitMemo = [];
        this.visible = false;
        break;
      case "Additional Amount":
        this.dataSource[this.activeKey].additionalAmounts = tempData;
        this.dataSource[this.activeKey].additionalAmount = sum(
          tempData.map<number>(item => item.amount)
        );
        this.dataSourceModalWriteOffAndAdditional = [];
        this.visible = false;
        break;
      default:
        break;
    }
  }

  handleAddData({ data, columnName }): void {
    this.visible = true;
    this.activeKey = data.key;
    switch (columnName) {
      case "writeOffAmount":
        this.title = "Write Off Amount";
        this.dataSourceModalWriteOffAndAdditional =
          this.dataSource[this.activeKey].writeOffAmounts.slice();
        break;
      case "invoiceDebitMemo":
        this.dataSourceModalInvoiceDebitMemo =
          this.dataSource[this.activeKey].invoiceDebitMemos.slice();
        this.title = "Invoice Debit Memo";
        break;
      case "additionalAmount":
        this.title = "Additional Amount";
        this.dataSourceModalWriteOffAndAdditional =
          this.dataSource[this.activeKey].additionalAmounts.slice();
        break;
      default:
        break;
    }
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  setFieldRequired(field: string, required: boolean): void {
    this.formRules[field].decorator = [
      field,
      {
        rules: [
          {
            required,
            message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
          },
        ],
      },
    ];
  }

  handlePrint(): void {
    this.loading.print = true;
    apPaymentServices
      .printApPayment(this.id)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        printJs(url);
      })
      .finally(() => (this.loading.print = false));
  }

  pushColumnTable() {
    this.columnsTable.splice(4, 0, {
      title: this.$t("lbl_write_off_amount"),
      dataIndex: "writeOffAmount",
      key: "writeOffAmount",
      scopedSlots: { customRender: "writeOffAmount" },
    });
    this.columnsTable.splice(5, 0, {
      title: "Invoice Debit Memo",
      dataIndex: "invoiceDebitMemo",
      key: "invoiceDebitMemo",
      scopedSlots: { customRender: "invoiceDebitMemo" },
    });
    this.columnsTable.splice(6, 0, {
      title: "Additional Amount",
      dataIndex: "additionalAmount",
      key: "additionalAmount",
      scopedSlots: { customRender: "additionalAmount" },
    });
  }

  hideShowColumnTable(value: "Prepayment" | "Invoice AP") {
    const writeOffAmount = this.columnsTable.findIndex(
      c => c.dataIndex === "writeOffAmount"
    );
    const invoiceDebitMemo = this.columnsTable.findIndex(
      c => c.dataIndex === "invoiceDebitMemo"
    );
    const additionalAmount = this.columnsTable.findIndex(
      c => c.dataIndex === "additionalAmount"
    );

    switch (value) {
      // ketika invoice ap munculin kolomnya
      case "Invoice AP":
        if (
          writeOffAmount === -1 &&
          invoiceDebitMemo === -1 &&
          additionalAmount === -1
        ) {
          this.pushColumnTable();
        }
        break;
      // ketika Prepayment hide kolomnya
      case "Prepayment":
        this.columnsTable.splice(writeOffAmount, 1);
        this.columnsTable.splice(invoiceDebitMemo - 1, 1);
        this.columnsTable.splice(additionalAmount - 2, 1);
        break;
      default:
        break;
    }
  }

  onChangeInvoiceType(value): void {
    this.form.resetFields();
    this.dataSource = [];
    this.hideShowColumnTable(value);
  }

  onChangeWriteOffCoa(value: string, record: Amount): void {
    this.dataSourceModalWriteOffAndAdditional[record.key].accountId = value;
  }

  /**
   * used by write off or additional amount modals
   */
  onChangeWriteOffAmount(value: number, record: Amount): void {
    this.dataSourceModalWriteOffAndAdditional[record.key].amount = value;
  }
}
